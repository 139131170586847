<template>
  <header id="header" class="fixed-top header-transparent" style="background-color: #67b0d1cc !important;">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo">        
        <router-link to="/"><img src="img/logo1.png" alt="" class="img-fluid" /></router-link>
      </div>
      <nav id="navbar" class="navbar" :class="{ 'navbar-mobile': sidebar }">
        
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li class="dropdown">
            <a href="#"><span>Profil</span><i class="bi bi-chevron-down" @click="dropdownxyz('dropdown1')"></i></a>
            <ul id='dropdown1'>
              <li @click="buka()"><router-link to="/tentang">Tentang</router-link></li>
              <li @click="buka()"><router-link to="/profilstruktural">Profil Pejabat Struktural</router-link></li>
              <li @click="buka()"><router-link to="/tugasfungsi">Tugas dan Fungsi</router-link></li>
              <li class="dropdown">
                <a href="#"><span>Bidang</span> <i class="bi bi-chevron-right" @click="dropdownxyz('dropdown2')"></i></a>
                <ul id='dropdown2'>
                  <li @click="buka()"><router-link to="/sekretariat">Sekretariat</router-link></li>
                  <li @click="buka()"><router-link to="/bidangikp">Bidang IKP</router-link></li>
                  <li @click="buka()"><router-link to="/bidangtik">Bidang TIK</router-link></li>
                  <li @click="buka()"><router-link to="bidangpersandian">Bidang Persandian</router-link></li>
                  <li @click="buka()"><router-link to="bidangdatacenter">Bidang Data Center</router-link></li>
                </ul>
              </li>
            </ul>
              <li @click="buka()"><router-link to="/strukturorganisasi">Struktur Organisasi</router-link></li>              
              <!-- <li><router-link to="/visimisi">Visi Misi</router-link></li>                             -->
               
          <li class="dropdown">
            <a href="#"><span>Informasi</span> <i class="bi bi-chevron-down" @click="dropdownxyz('dropdown3')"></i></a>
            <ul id="dropdown3">
              <li @click="buka()"><router-link to="/berita">Berita</router-link></li>
              <li @click="buka()"><router-link to="/pengumuman">Pengumuman</router-link></li>
              <li @click="buka()"><router-link to="/infografis">Infografis</router-link></li>
              <li @click="buka()"><a href="http://ppid.konaweselatankab.go.id">PPID</a></li>              
            </ul>
          </li>
          <li @click="buka()"><a href="https://dashboard-superapp.konaweselatankab.go.id/">Layanan</a></li>
          <!-- <li class="dropdown">            
            <router-link to="/layanan">Layanan</router-link>
          </li> -->
          <li class="dropdown">
            <a href="#"><span>Dokumen</span> <i class="bi bi-chevron-down" @click="dropdownxyz('dropdown4')"></i></a>
            <ul id="dropdown4">
              <li class="dropdown">
                <a href="#"><span>Laporan</span><i class="bi bi-chevron-right" @click="dropdownxyz('dropdown5')"></i></a>
                <ul id="dropdown5">
                  <li @click="buka()"><router-link to="/dpa">DPA</router-link></li>
                  <li @click="buka()"><router-link to="/lakip">Lakip</router-link></li>
                  <li @click="buka()"><router-link to="/lppd">LPPD</router-link></li>
                  <li @click="buka()"><router-link to="/perjanjian_kinerja">Perjanjian Kinerja</router-link></li>
                  <li @click="buka()"><router-link to="/pmprb">PMPRB</router-link></li>
                  <li @click="buka()"><router-link to="/renstra">Renstra</router-link></li>
                  <li @click="buka()"><router-link to="/rka">RKA</router-link></li>
                  <li @click="buka()"><router-link to="/renja">Renja</router-link></li>
                  <li @click="buka()"><router-link to="/peta">Peta SPBE</router-link></li>
                </ul>
              </li>
              <li @click="buka()"><router-link to="/peraturan">Peraturan</router-link></li>
              <li @click="buka()"><router-link to="/sop">SOP</router-link></li>
            </ul>            
          </li>
          <li class="dropdown">
            <a href="#"><span>Galeri</span> <i class="bi bi-chevron-down" @click="dropdownxyz('dropdown6')"></i></a>
            <ul id="dropdown6">
              <li @click="buka()"><router-link to="/foto">Foto</router-link></li>
              <li @click="buka()"><router-link to="/video">Video</router-link></li>
            </ul>
          </li>
          <li @click="buka()"><router-link to="/kontak">Kontak</router-link></li>
        </ul>

        <i class="bi bi-list mobile-nav-toggle" @click="bukasidebar()" ></i>
        
      </nav>
    </div>
  </header>
</template>

<script>
  export default {
    name: "NavBar",
    data() {
      return {
        sidebar:false,
        // dropdown1:false,
        // dropdown2:false,
        // dropdown3:false,
        // dropdown4:false,
        // dropdown5:false,
        // dropdown6:false,

      }
    },
    methods: {
      bukasidebar(){
        this.sidebar = !this.sidebar
        console.log(this.sidebar);
        // sidebar
      },

      dropdownxyz (lala){
        var element = document.getElementById(lala);
        element.classList.toggle("dropdown-active");
      },
      // bukadropdown1(){
      //   this.dropdown1 = !this.dropdown1
      //   console.log(this.dropdown1);
      // },
      // bukadropdown2(){
      //   this.dropdown2 = !this.dropdown2
      //   console.log(this.dropdown2);
      // },
      // bukadropdown3(){
      //   this.dropdown3 = !this.dropdown3
      //   console.log(this.dropdown3);
      // },
      // bukadropdown4(){
      //   this.dropdown4 = !this.dropdown4
      //   console.log(this.dropdown4);
      // },
      // bukadropdown5(){
      //   this.dropdown5 = !this.dropdown5
      //   console.log(this.dropdown5);
      // },
      // bukadropdown6(){
      //   this.dropdown6 = !this.dropdown6
      //   console.log(this.dropdown6);
      // },
      buka(){
        this.sidebar = false
        console.log(this.sidebar);
      },
    },
  }
</script>

<style></style>
