<template>
  <div id="app">
    <div class="container-fluid">
      <NavBar />
      <router-view />
    </div>


    <foot />
  </div>
</template>

<script>
import Foot from './components/foot.vue'

export default {

  components : {
    Foot
  
  }
    
}
</script>

<style>

</style>
